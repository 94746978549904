module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wynne-the-pooh","short_name":"starter","start_url":"/","background_color":"#CC8E20","theme_color":"#CC8E20","display":"minimal-ui","icon":"src/images/wynnethepooh-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1529f49729a9794e15afc42c09317ea9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wynne the pooh","short_name":"wynne the pooh","start_url":"/","background_color":"#ffffff","theme_color":"#CC8E20","display":"minimal-ui","icon":"src/images/wynnethepooh-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1529f49729a9794e15afc42c09317ea9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
